import React, { Fragment, useMemo, useState } from 'react'
import { IndividualChartLineProps } from './types'
import {
  FunctionalButton,
  ToggledCollapsibleBlock
} from '../../helperComponents/_components'
import AppearanceBlock from '../helperComponents/AppearanceBlock'
import { ChartLine, Frequency } from '../interfaces'
import CombineIndicatorFormula from './CombineIndicatorFormula'
import {
  chartlinesFetcher,
  createNewIndicatorCalculated,
  getCalculatedFromEquation,
  updateEquationCalculatedIndicator
} from '../../utils/fetch'
import { IEquationPiece, IIndicator } from '../../utils/interfaces'
import { formatSelectedChartSeries } from './utils/functions'
import QuickSearch from '../../search/QuickSearch'
import { capitalise } from '../../utils/functions'

const IndividualChartLine = (props: IndividualChartLineProps) => {
  const [line, setLine] = useState<ChartLine>(props.chartLine)
  const [editingTitle, setEditingTitle] = useState<boolean>(false)
  const [title, setTitle] = useState<string>(line.appearance_settings.title)

  const initialChartLine = useMemo(() => props.chartLine, [])

  const updateLine = (newLine: ChartLine) => {
    formatSelectedChartSeries(
      {
        color: newLine.appearance_settings.color,
        dashStyle: newLine.appearance_settings.dashStyle,
        width: newLine.appearance_settings.width,
        yAxis: newLine.appearance_settings.yAxis,
        title: newLine.appearance_settings.title
      },
      [
        newLine.appearance_settings.title,
        props.chartLine.appearance_settings.title,
        title
      ]
    )

    setLine(newLine)
    props.updateChartLine(newLine)

    return true
  }

  const deleteLine = () => {
    props.updateChartLine(line.fid)
  }

  const addToIndicators = (indicator: string, title?: string) => {
    if (title) {
      // TECH DEBT: This is a hack to make it work with the current implementation
      props.allIndicators.push({
        fid: indicator,
        title,
        type: 'external'
      } as IIndicator)
    }

    return updateLine({
      ...line,
      data_settings: {
        ...line.data_settings,
        indicators: [
          ...line.data_settings.indicators,
          {
            value: indicator,
            type: title ? 'external' : 'indicator', // TECH DEBT: This is a hack to make it work with the current implementation
            offset: 0,
            cagr: 0,
            aggregationMethod: 'first',
            mode: 'values_absolute'
          }
        ]
      }
    })
  }

  const handleCreateNewCalculated = async () => {
    const equationToUse = line.data_settings.equation
    if (!equationToUse || equationToUse.length === 0) {
      return false
    }

    const fid = await createNewIndicatorCalculated({
      title: title + ' (calculated)',
      dataMode: 'values',
      computationMode: 'calculated',
      noRefresh: true
    })

    if (!fid) {
      return false
    }

    const updated = await updateEquationCalculatedIndicator({
      indicatorId: fid,
      equation: equationToUse as IEquationPiece[]
    })

    if (!updated) {
      return false
    }

    const updatedLine = await chartlinesFetcher({
      oneOrMany: 'one',
      method: 'convert',
      props: {
        newOriginFid: fid,
        chartLine: line
      }
    })

    if (!updatedLine) {
      return false
    }

    // window.switchFunctions.calculated(fid)
    window.open(`/app?t=o&i=${fid}`, '_blank')

    return true
  }

  const handleSubmitEquation = async (
    equation: IEquationPiece[],
    indicators: IEquationPiece[],
    noFetch = false
  ) => {
    const newLine = {
      ...line,
      appearance_settings: {
        ...line.appearance_settings,
        title
      },
      data_settings: {
        ...line.data_settings,
        equation,
        indicators
      }
    }
    updateLine(newLine)

    if (noFetch) {
      return true
    }

    const res = await getCalculatedFromEquation(newLine)

    if (!res || !res.series || res.data.length === 0) {
      return false
    }

    formatSelectedChartSeries(
      {
        color: res.series.color,
        title,
        data: res.series.data
      },
      [props.chartLine.appearance_settings.title, title]
    )

    setLine((prev) => ({
      ...prev,
      data_settings: {
        ...prev.data_settings,
        frequenciesToChooseFrom:
          res.chartline.data_settings.frequenciesToChooseFrom
      }
    }))

    return true
  }

  return (
    <Fragment>
      <div className="col-12 flex justify-between">
        <div className="flex gap-2">
          {!editingTitle && (
            <>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap w-[auto] max-w-[250px]">
                {line ? title : ''}
              </span>
              <FunctionalButton
                initialButtonState="Edit"
                doesReset
                disabled={props.readOnly}
                functionToExecute={() => setEditingTitle(true)}
                noReturn
                className="secondary icon inline no-btn scale-90"
                iconEditMode
              />
              <FunctionalButton
                initialButtonState="Convert"
                functionToExecute={handleCreateNewCalculated}
                className="secondary icon inline no-btn"
                iconCalculate
                doesReset
              />
            </>
          )}
          {editingTitle && (
            <>
              <input
                id="seriesDisplayTitle"
                type="text"
                className="inline m-0 wider"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <FunctionalButton
                initialButtonState="Undo"
                doesReset
                disabled={props.readOnly}
                functionToExecute={() => setEditingTitle(false)}
                className="teriary icon scale-90"
                iconUndoMode
                noReturn
              />
              <FunctionalButton
                initialButtonState="Save"
                doesReset
                disabled={props.readOnly}
                noReturn
                functionToExecute={() => {
                  updateLine({
                    ...line,
                    appearance_settings: {
                      ...line.appearance_settings,
                      title
                    }
                  })
                  setEditingTitle(false)
                }}
                className="primary icon scale-90"
                iconSaveMode
              />
            </>
          )}
        </div>
        <div className="flex gap-2">
          <FunctionalButton
            disabled={props.pageDetails.type !== 'scenario' || props.readOnly}
            className="destructive icon"
            functionToExecute={deleteLine}
            iconDelete
            initialButtonState="Remove"
            doesReset
          />
        </div>
      </div>
      <AppearanceBlock
        initialSettings={line.appearance_settings}
        updateSettings={(settings) =>
          updateLine({
            ...line,
            appearance_settings: {
              ...settings,
              title
            }
          })
        }
      />
      <ToggledCollapsibleBlock
        initialState={true}
        title="Data"
        className="action"
      >
        <div className="my-2">
          {/* <IndicatorsAndTrendlinesSearch
            indicators={props.allIndicators || []}
            customMessage="Add Lines to Equation"
            includedIndicators={[]}
            refreshIndicators={() => {}}
            direction="down"
            inline
            disabled={
              !(
                props.pageDetails.type === 'scenario' ||
                props.pageDetails.type === 'calculated'
              )
            }
            addFunction={(indicator) => addToIndicators(indicator)}
            altFunction={async (id: string) => {}}
          /> */}
          <QuickSearch
            disabled={
              props.readOnly ||
              (props.pageDetails.type !== 'scenario' &&
                props.pageDetails.type !== 'calculated')
            }
            aiSearchEnabled={
              props.pageDetails.type === 'scenario' ||
              props.pageDetails.type === 'calculated'
            }
            selection={[]}
            fetchedAllIndicators={props.allIndicators}
            sourceInfo={{
              entityType: props.pageDetails.type,
              entityId: props.pageDetails.fid
            }}
            updateIncluded={(id: string, title: string, add: boolean) =>
              addToIndicators(id, title)
            }
            refreshEntity={props.pageDetails.refresh}
          />
        </div>
        <div className="px-2">
          <div className="flex col-12 gap-1 text-[0.8rem]">
            <span>Frequency for all:</span>
            <select
              className="inline mx-0"
              id="frequency"
              value={line.data_settings.currentFrequency}
              onChange={(e) =>
                updateLine({
                  ...line,
                  data_settings: {
                    ...line.data_settings,
                    currentFrequency: e.target.value as Frequency
                  }
                })
              }
            >
              {line.data_settings.frequenciesToChooseFrom.map((freq) => (
                <option key={freq} value={freq}>
                  {capitalise(freq)}
                </option>
              ))}
            </select>
          </div>
          {line.data_settings.currentFrequency === 'monthly' && (
            <div className="flex col-12 gap-1 text-[0.8rem]"></div>
          )}
          <CombineIndicatorFormula
            initialAvailableIndicators={
              initialChartLine.data_settings.indicators
            }
            initialEquation={initialChartLine.data_settings.equation}
            currentAvailableIndicators={line.data_settings.indicators.filter(
              (indicator) => indicator.type !== 'operator'
            )}
            frequency={line.data_settings.currentFrequency}
            allIndicators={props.allIndicators}
            updateEquationFunction={(equation, includedIndicators, noFetch) =>
              handleSubmitEquation(equation, includedIndicators, noFetch)
            }
          />
        </div>
      </ToggledCollapsibleBlock>
    </Fragment>
  )
}

export default IndividualChartLine
