import React, { Fragment, useEffect, useState } from 'react'
import {
  calculateDeviationsParams,
  updateTrendlineParameters
} from '../utils/fetch'
import {
  ITrendline,
  IBasicIndicator,
  ICalculatedIndicator,
  IForecast,
  ITrendlinesDisplay,
  IRow,
  IFetchedDataEntry
} from '../utils/interfaces'
import PopupModal from '../helperComponents/PopUpModal'
import {
  TabbingSwitch,
  FunctionalButton,
  FunctionalToggle,
  TrendlinesBlocks
} from '../helperComponents/_components'
import { Checkbox } from 'pretty-checkbox-react'

import {
  getFilteredKeys,
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import RangeSlider2 from '../helperComponents/RangeSlider2'
import { calculateAAGR } from '../charts/_googleChartFunctions'
import ChatbotComponent from '../helperComponents/ChatbotComponent'
import EntityTags from '../helperComponents/EntityTags'
import EntitySharingControls from '../helperComponents/EntitySharingControls'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import DataTableValues from '../helperComponents/DataTableValues'

const Trendlines = ({
  readOnly,
  trendlines,
  allIndicators,
  entityChartKeys,
  forecastsAssosiated,
  fullDates,
  chartsOnly,
  selectedDataBoundaries,
  actuals,
  functions,
  userTags,
  data,
  indicatorPageDependent
}: ITrendlinesDisplay) => {
  if (!trendlines) {
    return (
      <div className="center middle col-12 flex-column">
        <h2>No trendlines found</h2>
        <h4>Create a trendline to see it here</h4>
        {functions.handleCreateTrendline !== undefined && !readOnly && (
          <FunctionalButton
            className="primary wider"
            functionToExecute={functions.handleCreateTrendline}
            initialButtonState="Create Trendline"
            iconPlusMode
            wide
            combinedButtonTitle
          />
        )}
      </div>
    )
  }

  const [, setAllIndicatorsModified] =
    useState<(ITrendline | IBasicIndicator | ICalculatedIndicator)[]>(
      allIndicators
    ) // TECH DEBT

  const [indicatorsData, setIndicatorsData] = useState<IRow[]>(data)
  const [modalUpdateConfigurationId, setModalUpdateConfigurationId] =
    useState('')
  const [detailsTabMode, setDetailsTabMode] = useState('configuration')
  const [AAGR, setAAGR] = useState<number>(0)
  const [computationMode, setComputationMode] = useState<string>('')
  // const [equationPart, setEquationPart] = useState<string>('')
  const [numberOfMonthsForAverage, setNumberOfMonthsForAverage] =
    useState<number>(0)
  const [numberOfMonthForData, setNumberOfMonthForData] = useState<number>(0)
  const [trendlineCalculationMode, setTrendlineCalculationMode] =
    useState<boolean>(false)
  const [initialTrendlineInFocus, setInitialTrendlineInFocus] = useState(
    {} as ITrendline
  )
  const [showControls, setShowControls] = useState<boolean>(false)
  const [trendlineInFocus, setTrendlineInFocus] = useState({} as ITrendline)
  const [trendlineInFocusForecasts, setTrendlineInFocusForecasts] = useState<
    IForecast[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedForecast, setSelectedForecast] = useState<IForecast>()
  const [dataAttached, setDataAttached] = useState<boolean>(true)
  const [chartTitles, setChartTitles] = useState<
    {
      [key: string]: string
    }[]
  >([])

  const [chartRelative, setChartRelative] = useState<boolean>(false)
  const [trendlineInverse, setTrendlineInverse] = useState<boolean>(false)

  const [rangeSelected, setRangeSelected] = useState<string[]>([])

  const resetModals = () => {
    window.collapseSidePanel(true)
    setModalUpdateConfigurationId('')
  }

  const undoChanges = () => {
    setIndicatorsData(data)
    setTrendlineInFocus(initialTrendlineInFocus)
    setRangeSelected(
      transformFetchedRangedDetailsScenariotoRangeInputs(
        initialTrendlineInFocus.range_chosen_deviation
      )
    )
    setComputationMode(initialTrendlineInFocus.standard_deviation_chart_mode)
    setChartRelative(initialTrendlineInFocus.standard_deviation_relative)
    setTrendlineInverse(initialTrendlineInFocus.inverse)
    setAAGR(
      calculateAAGR(
        JSON.parse(initialTrendlineInFocus.standard_deviation_parameters).b
      )
    )
    setNumberOfMonthForData(
      Number(initialTrendlineInFocus.cellular_calculation_mode_months)
    )
    setTrendlineCalculationMode(
      initialTrendlineInFocus.cellular_calculation_mode
    )

    setShowControls(false)

    return true
  }

  const checkIfAnyOfIntitalAllParamsAreDifferent = () => {
    try {
      if (
        rangeSelected[0] !==
          transformFetchedRangedDetailsScenariotoRangeInputs(
            initialTrendlineInFocus.range_chosen_deviation
          )[0] ||
        rangeSelected[1] !==
          transformFetchedRangedDetailsScenariotoRangeInputs(
            initialTrendlineInFocus.range_chosen_deviation
          )[1]
      ) {
        return {
          issue: 'range_chosen_deviation',
          success: false
        }
      } else if (
        chartRelative !== initialTrendlineInFocus.standard_deviation_relative
      ) {
        return {
          issue: 'standard_deviation_relative',
          success: false
        }
      } else if (
        computationMode !==
        initialTrendlineInFocus.standard_deviation_chart_mode
      ) {
        return {
          issue: 'standard_deviation_chart_mode',
          success: false
        }
      } else if (
        initialTrendlineInFocus.standard_deviation_parameters &&
        typeof initialTrendlineInFocus.standard_deviation_parameters ===
          'string' &&
        AAGR !==
          calculateAAGR(
            JSON.parse(initialTrendlineInFocus.standard_deviation_parameters).b
          )
      ) {
        return {
          issue: 'AAGR',
          success: false
        }
      } else if (
        numberOfMonthForData !==
        Number(initialTrendlineInFocus.cellular_calculation_mode_months)
      ) {
        return {
          issue: 'numberOfMonthForData',
          success: false
        }
      } else if (trendlineInverse !== initialTrendlineInFocus.inverse) {
        return {
          issue: 'trendlineInverse',
          success: false
        }
      }

      return {
        issue: '',
        success: true
      }
    } catch (e) {
      return {
        issue: '',
        success: true
      }
    }
  }

  const selectTrendline = (fid: string) => {
    try {
      const trendline = trendlines.find((item) => item.fid === fid)
      if (!trendline) return false

      setInitialTrendlineInFocus(trendline)
      setDataAttached(trendline.data_attached)
      setTrendlineInFocus(trendline)
      setTrendlineInFocusForecasts(forecastsAssosiated)
      setNumberOfMonthsForAverage(
        trendline.standard_deviation_chart_mode?.includes('average')
          ? Number(trendline.standard_deviation_chart_mode.split('---')[1])
          : 0
      )
      setNumberOfMonthForData(
        Number(trendline.cellular_calculation_mode_months)
      )
      setTrendlineCalculationMode(trendline.cellular_calculation_mode)
      setComputationMode(trendline.standard_deviation_chart_mode)
      setChartRelative(trendline.standard_deviation_relative)
      setTrendlineInverse(trendline.inverse)
      setAAGR(
        calculateAAGR(JSON.parse(trendline.standard_deviation_parameters).b)
      )
      setRangeSelected(
        transformFetchedRangedDetailsScenariotoRangeInputs(
          trendline.range_chosen_deviation
        )
      )

      setModalUpdateConfigurationId(fid)
    } catch (err: any) {
      console.error(err)
    }
  }

  const handlePreview = async () => {
    console.time('handlePreview')
    const analysed = await calculateDeviationsParams({
      trendline: {
        ...trendlineInFocus,
        range_chosen_deviation: `[${rangeSelected[0]},${rangeSelected[1]})`,
        standard_deviation_chart_mode: computationMode,
        standard_deviation_relative: chartRelative,
        inverse: trendlineInverse
      },
      multiple: true,
      sliced: false,
      recalculateParams: true,
      frequency: trendlineInFocus.frequency,
      baseIndicatorTitle: actuals[0].title,
      baseIndicatorsFid: trendlineInFocus.base_indicator
    })

    const mergedData = analysed.merged
    setIndicatorsData(mergedData)
    setShowControls(true)
    console.timeEnd('handlePreview')

    return true
  }

  const handleSubmit = async () => {
    try {
      const analysed = await calculateDeviationsParams({
        trendline: {
          ...trendlineInFocus,
          range_chosen_deviation: `[${rangeSelected[0]},${rangeSelected[1]})`,
          standard_deviation_chart_mode: computationMode,
          standard_deviation_relative: chartRelative,
          inverse: trendlineInverse
        },
        multiple: true,
        sliced: false,
        recalculateParams: true,
        frequency: trendlineInFocus.frequency,
        baseIndicatorTitle: actuals[0].title,
        baseIndicatorsFid: trendlineInFocus.base_indicator
      })

      const res = await updateTrendlineParameters({
        fid: trendlineInFocus.fid,
        standard_deviation: analysed.deviation,
        standard_deviation_parameters: {
          a: analysed.a,
          b: analysed.b
        },
        standard_deviation_chart_mode:
          analysed.trendline.standard_deviation_chart_mode,
        standard_deviation_relative:
          analysed.trendline.standard_deviation_relative,
        range_chosen_deviation: analysed.trendline.range_chosen_deviation,
        inverse: analysed.trendline.inverse
      })

      if (res) {
        window.location.reload()
        return true
      }
    } catch (e) {
      return false
    }
    return false
  }

  const initialSetup = () => {
    try {
      setLoading(true)
      setAllIndicatorsModified(
        allIndicators.map((item) => {
          const itemInTrendlines = trendlines.find(
            (trendline) => trendline.fid === item.fid
          )
          if (itemInTrendlines) {
            return {
              ...item,
              onclick: () => selectTrendline(item.fid)
            }
          }
          return item
        })
      )
      setTrendlineInFocusForecasts(forecastsAssosiated)
      setChartTitles([
        ...trendlines.map((item) => ({ [item.fid]: item.title })),
        ...trendlines.map((item) => ({ [item.fid + ' Value']: item.title })),
        ...trendlines.map((item) => ({
          [item.fid + ' Deviation']: item.title
        })),
        ...actuals.map((item) => ({ [item.fid]: item.title }))
      ])
      setLoading(false)
    } catch (err: any) {
      console.error(err)
      window.switchFunctions.home()
    }
  }

  useEffect(() => {
    if (
      // eslint-disable-next-line
      !trendlineInFocus ||
      !trendlineInFocus.fid ||
      !initialTrendlineInFocus ||
      !initialTrendlineInFocus.fid
    ) {
      return
    }
    if (
      Object.keys(trendlineInFocus).length > 0 &&
      Object.keys(initialTrendlineInFocus).length > 0 &&
      !isEmpty(rangeSelected[0]) &&
      !isEmpty(rangeSelected[1]) &&
      !showControls
    ) {
      const { success } = checkIfAnyOfIntitalAllParamsAreDifferent()
      setShowControls(!success)
    }
  }, [trendlineInFocus, rangeSelected])

  useEffect(() => {
    initialSetup()
  }, [])

  return (
    <Fragment>
      {loading && <TrendlinesBlocks />}
      <div
        className="container w-6 auto no-scrollbar"
        style={{
          display: loading ? 'none' : 'block'
        }}
      >
        <div className="entity-chart">
          <HighChartsLinearChart
            pageDetails={{
              type: 'trendline',
              title: trendlineInFocus.title,
              fid: trendlineInFocus.fid,
              refresh: window.location.reload
            }}
            page="trendline"
            chartOptions={{
              xAxisTitle: 'Month',
              yAxisTitle: 'Value',
              title: 'Values',
              oneAxis: true,
              downloadButton: true,
              reactive: true,
              mediumSize: true,
              rangeSelected: selectedDataBoundaries
            }}
            chartData={{
              fetchedData: data,
              filteredKeys: getFilteredKeys({
                keys: entityChartKeys,
                mode: 'values',
                backupFids: [
                  ...trendlines.map((item) => item.fid),
                  ...actuals.map((item) => item.fid)
                ]
              }),
              fullDates,
              titles: chartTitles
            }}
            componentOptions={{
              noTrendlines: true,
              own: !readOnly
            }}
          />
        </div>
      </div>
      <div
        className="container w-6 auto no-scrollbar"
        style={{
          display: loading ? 'none' : 'block'
        }}
      >
        <div className="entity-chart">
          <HighChartsLinearChart
            pageDetails={{
              type: 'trendline',
              title: trendlineInFocus.title,
              fid: trendlineInFocus.fid,
              refresh: window.location.reload
            }}
            page="trendline"
            chartOptions={{
              xAxisTitle: 'Month',
              yAxisTitle: 'Value',
              title: 'Residuals',
              downloadButton: true,
              reactive: true,
              mediumSize: true,
              oneAxis: true
            }}
            chartData={{
              titles: chartTitles,
              fetchedData: data,
              fullDates,
              filteredKeys: getFilteredKeys({
                keys: entityChartKeys,
                mode: 'deviations',
                backupFids: [...trendlines.map((item) => item.fid)],
                bannedFids: actuals.map((item) => item.fid)
              })
            }}
            componentOptions={{
              noTrendlines: true,
              own: !readOnly
            }}
          />
        </div>
      </div>
      {!chartsOnly && (
        <div
          className="container w-12 fit no-scroll"
          style={{
            display: loading ? 'none' : 'block'
          }}
        >
          <DataTableValues
            data={indicatorsData as IFetchedDataEntry[]}
            parameters={
              trendlineInFocus.standard_deviation_parameters
                ? JSON.parse(trendlineInFocus.standard_deviation_parameters)
                : {}
            }
          />
        </div>
      )}
      <PopupModal
        isOpen={modalUpdateConfigurationId !== ''}
        onClose={resetModals}
        title={
          'Update Configuration for ' +
          allIndicators.find(
            (item: IBasicIndicator) => item.fid === modalUpdateConfigurationId
          )?.title
        }
        size="largest"
        handleSubmit={() => undefined}
        saveButtonExists={false}
        inner
        noChanges
        className="p-0"
      >
        <div
          className="entity-blocks"
          style={{
            height: '100%'
          }}
        >
          <div className="entity-parameters">
            <TabbingSwitch
              options={[
                {
                  label: 'Configuration',
                  active: detailsTabMode === 'configuration',
                  onClick: () => setDetailsTabMode('configuration')
                },
                {
                  label: 'Data',
                  active: detailsTabMode === 'data',
                  onClick: () => setDetailsTabMode('data')
                },
                {
                  label: 'Details',
                  active: detailsTabMode === 'description',
                  onClick: () => setDetailsTabMode('description')
                },
                {
                  label: 'AI',
                  active: detailsTabMode === 'ai',
                  onClick: () => setDetailsTabMode('ai'),
                  exists: !readOnly
                },
                {
                  label: 'Tags',
                  active: detailsTabMode === 'tags',
                  onClick: () => setDetailsTabMode('tags'),
                  exists: !readOnly
                },
                {
                  label: 'Sharing',
                  active: detailsTabMode === 'sharing',
                  onClick: () => setDetailsTabMode('sharing'),
                  exists: !readOnly
                }
              ]}
              numberVisible={10}
            />
          </div>
          {detailsTabMode === 'description' && (
            <textarea
              className="description default-text p-2"
              contentEditable={false}
              readOnly
              value={trendlineInFocus.meaning || 'No description provided'}
            />
          )}
          <div
            style={{
              display: detailsTabMode === 'ai' ? 'block' : 'none'
            }}
            className="flex-row col-12"
          >
            <ChatbotComponent page="entity" />
          </div>
          {detailsTabMode === 'data' && !readOnly && (
            <div className="col-12 center middle">
              <div className="flex p-3 middle gap-3">
                <Checkbox
                  checked={dataAttached}
                  onChange={() => {
                    setDataAttached(!dataAttached)
                    return true
                  }}
                  color="success-o"
                  animation="pulse"
                  shape="curve"
                >
                  Use Actual Data
                </Checkbox>
                <select
                  value={selectedForecast ? selectedForecast.fid : ''}
                  onChange={(e) => {
                    const selected = trendlineInFocusForecasts.find(
                      (item: IForecast) => item.fid === e.target.value
                    )
                    setSelectedForecast(selected)
                  }}
                  className="widest"
                >
                  <option value="">None selected</option>
                  {trendlineInFocusForecasts.map((item: IForecast) => (
                    <option key={item.fid} value={item.fid}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <FunctionalButton
                  iconMinusMode
                  initialButtonState="Remove"
                  functionToExecute={() => {
                    setSelectedForecast(undefined)
                    return true
                  }}
                />
                <FunctionalButton
                  className="primary border center middle"
                  functionToExecute={() =>
                    updateTrendlineParameters({
                      fid: trendlineInFocus.fid,
                      forecast_attached:
                        selectedForecast !== undefined
                          ? selectedForecast.fid
                          : '',
                      data_attached: dataAttached
                    })
                  }
                  initialButtonState="Save"
                  iconSaveMode
                  combinedButtonTitle
                  disabled={
                    (selectedForecast &&
                      selectedForecast.fid ===
                        trendlineInFocus.forecast_attached &&
                      dataAttached === trendlineInFocus.data_attached) ||
                    (!selectedForecast && !dataAttached)
                  }
                  refreshOnComplete={{
                    exists: true,
                    refreshFunction: window.location.reload
                  }}
                />
              </div>
            </div>
          )}

          {detailsTabMode === 'tags' && !readOnly && (
            <div className="col-12 p-2">
              <EntityTags
                type="trendline"
                allTags={userTags}
                entityTags={trendlineInFocus.tags || []}
                entityId={trendlineInFocus.fid}
                functionRefresh={window.location.reload}
              />
            </div>
          )}

          {detailsTabMode === 'sharing' && (
            <div className="description flex-row col-12">
              <EntitySharingControls
                entityType="trendline"
                entityId={trendlineInFocus.fid}
                ChannelContentPiece={trendlineInFocus.ChannelContentPiece}
                refreshFunction={window.location.reload}
                initialDescription={trendlineInFocus.meaning}
              />
            </div>
          )}

          {rangeSelected.length > 0 &&
            fullDates &&
            detailsTabMode === 'configuration' && (
              <div className="col-12">
                <div className="flex-column">
                  <div className="flex gap-2 middle m-0 p-2 col-12">
                    {!readOnly && (
                      <FunctionalToggle
                        leftTitle="Rolling"
                        rightTitle=""
                        state={trendlineCalculationMode}
                        functionToExecute={() => {
                          setTrendlineCalculationMode(!trendlineCalculationMode)
                          return true
                        }}
                        disabled={true}
                      />
                    )}
                  </div>
                  <div className="flex gap-2 middle m-0 p-2 col-12 space-between">
                    <RangeSlider2
                      dataArray={fullDates}
                      initialDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                        trendlineInFocus.range_complete
                      )}
                      selectedDetails={rangeSelected}
                      dateSliderMode={true}
                      readOnly={readOnly}
                      iconMode={'calculate'}
                      customStyles={{
                        width: 400,
                        padding: '6px 20px 6px 5px'
                      }}
                      noSlider={true}
                      noApplyButton={true}
                      onApply={() => undefined}
                      handleChange={(value: string[]) => {
                        const newRange = value.map((item) => item.split('T')[0])
                        const newRangeForDb = `[${newRange[0]},${newRange[1]})`
                        setRangeSelected(newRange)
                        setTrendlineInFocus({
                          ...trendlineInFocus,
                          range_chosen_deviation: newRangeForDb
                        })
                      }}
                    />
                  </div>
                  <div className="flex gap-2 middle m-0 p-2 col-12">
                    <label>Method:</label>
                    <select
                      value={computationMode || 'exponential'}
                      onChange={(e) => {
                        setComputationMode(e.target.value)
                        setTrendlineInFocus({
                          ...trendlineInFocus,
                          standard_deviation_chart_mode: e.target.value
                        })
                      }}
                      disabled={readOnly}
                    >
                      <option value="exponential">Exponential</option>
                      <option value="linear">Linear</option>
                      {/* <option value="cagr">CAGR</option> */}
                      {/* <option value="average">Moving Average</option> */}
                    </select>
                    {computationMode === 'average' && (
                      <input
                        min={0}
                        type="number"
                        className="narrower"
                        value={numberOfMonthsForAverage}
                        onChange={(e) => {
                          setNumberOfMonthsForAverage(Number(e.target.value))
                          setTrendlineInFocus({
                            ...trendlineInFocus,
                            standard_deviation_chart_mode: `average---${e.target.value}`
                          })
                        }}
                      />
                    )}
                  </div>
                  <div className="flex col-12 margin-1 ">
                    {!readOnly && (
                      <Checkbox
                        checked={chartRelative}
                        onChange={() => {
                          setTrendlineInFocus({
                            ...trendlineInFocus,
                            standard_deviation_relative: !chartRelative
                          })
                          setChartRelative(!chartRelative)
                          return true
                        }}
                        color="success-o"
                        animation="pulse"
                        shape="curve"
                        disabled={false}
                      >
                        Relative
                      </Checkbox>
                    )}
                    {!readOnly && (
                      <Checkbox
                        checked={trendlineInverse}
                        onChange={() => {
                          setTrendlineInFocus({
                            ...trendlineInFocus,
                            inverse: !trendlineInverse
                          })
                          setTrendlineInverse(!trendlineInverse)

                          return true
                        }}
                        color="success-o"
                        animation="pulse"
                        shape="curve"
                        disabled={false}
                      >
                        Inverse colours
                      </Checkbox>
                    )}
                  </div>
                  <div>
                    <span className="flex gap-1 middle py-1 d-none">
                      Average annual growth:
                      <input
                        type="number"
                        step={0.005}
                        className="narrow"
                        value={AAGR}
                        onChange={(e) => setAAGR(Number(e.target.value))}
                      />{' '}
                      %
                    </span>
                  </div>
                  <span className="flex middle gap-2 p-2 col-12 d-none">
                    Months to offset:{' '}
                    <input
                      type="number"
                      onChange={(e) =>
                        setNumberOfMonthForData(Number(e.target.value))
                      }
                      className="narrower"
                      value={numberOfMonthForData}
                    />
                  </span>
                </div>
                {showControls && (
                  <div className="flex col-12 p-2 align-items-start center gap-3">
                    <FunctionalButton
                      className="teriary wide"
                      initialButtonState={'Undo'}
                      iconUndoMode
                      combinedButtonTitle
                      functionToExecute={undoChanges}
                      doesReset
                    />
                    <FunctionalButton
                      className="secondary wide"
                      initialButtonState={'Preview'}
                      functionToExecute={handlePreview}
                      doesReset
                    />
                    <FunctionalButton
                      className="primary wide"
                      initialButtonState={'Submit'}
                      combinedButtonTitle
                      iconSaveMode
                      functionToExecute={handleSubmit}
                      refreshOnComplete={{
                        exists: true,
                        refreshFunction: window.location.reload
                      }}
                      doesReset
                    />
                  </div>
                )}
              </div>
            )}

          <div className="container w-6 auto no-scrollbar">
            <div className="entity-chart">
              <HighChartsLinearChart
                pageDetails={{
                  type: 'trendline',
                  title: trendlineInFocus.title,
                  fid: trendlineInFocus.fid,
                  refresh: window.location.reload
                }}
                page="trendline"
                chartOptions={{
                  xAxisTitle: 'Month',
                  yAxisTitle: 'Value',
                  title: 'Values',
                  oneAxis: true,
                  downloadButton: true,
                  reactive: true,
                  rangeSelected: selectedDataBoundaries
                }}
                chartData={{
                  fetchedData: indicatorsData,
                  filteredKeys: [
                    actuals[0].title,
                    `${trendlineInFocus.title} Value`,
                    `${trendlineInFocus.fid} Value`
                  ],
                  fullDates,
                  titles: chartTitles
                }}
                componentOptions={{
                  noTrendlines: true,
                  own: !readOnly
                }}
              />
            </div>
          </div>

          <div className="container w-6 auto no-scrollbar">
            <div className="entity-chart">
              <HighChartsLinearChart
                pageDetails={{
                  type: 'trendline',
                  title: trendlineInFocus.title,
                  fid: trendlineInFocus.fid,
                  refresh: window.location.reload
                }}
                page="trendline"
                chartOptions={{
                  xAxisTitle: 'Month',
                  yAxisTitle: 'Value',
                  title: 'Residuals',
                  downloadButton: true,
                  reactive: true,
                  oneAxis: true
                }}
                chartData={{
                  titles: chartTitles,
                  fetchedData: indicatorsData,
                  filteredKeys: [
                    trendlineInFocus.title + ' Residual Over Deviation', // this will take care of the updated data
                    `${trendlineInFocus.fid} Deviation` // this is legacy that will take care of the inherited data
                  ],
                  fullDates
                }}
                componentOptions={{
                  noTrendlines: true,
                  own: !readOnly
                }}
              />
            </div>
          </div>
        </div>
      </PopupModal>
    </Fragment>
  )
}

export { Trendlines }
