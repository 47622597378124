import React, { useEffect, useState } from 'react'
import * as xlsx from 'xlsx'
import { IDataMapped, IMappedDataEntry } from '../utils/interfaces'
import { convertExcelDateToHTMLDate } from '../utils/transformingData'

interface IRow {
  date: string
  value: string | number | null
}

export default function ExcelUploadForm({
  handleDataCheckFailed,
  handleDataCheckSuccess,
  handleDataCheckReset,
  mode,
  customData
}: {
  handleDataCheckFailed: (message: string) => void
  handleDataCheckSuccess: (data: IMappedDataEntry) => void
  handleDataCheckReset: () => void
  mode: 'upload' | 'table'
  customData?: IMappedDataEntry
}) {
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const [checkingDataInProcess, setCheckingDataInProcess] =
    useState<boolean>(false)
  const [dataCheckPassed, setDataCheckPassed] = useState<boolean>(false)
  const [data, setData] = useState<IDataMapped>()
  const [rows, setRows] = useState<IRow[]>([{ date: '', value: '' }])
  // const [mode, setMode] = useState<'upload' | 'table'>('table')

  const uploadFailed = () => {
    setUploadSuccess(false)
    setData(undefined)
  }

  const uploadSuccessful = () => {
    setUploadSuccess(true)
  }

  const startUpload = () => {
    setUploadSuccess(false)
    setData(undefined)
  }

  const startCheckingData = () => {
    setCheckingDataInProcess(true)
    setDataCheckPassed(false)
  }

  const dataCheckFailed = (message: string) => {
    setCheckingDataInProcess(false)
    setDataCheckPassed(false)
    handleDataCheckFailed(message)
  }

  const dataCheckSuccess = () => {
    if (data) {
      setCheckingDataInProcess(false)
      setDataCheckPassed(true)
      handleDataCheckSuccess({
        dates: data.data[0].dates,
        values: data.data[0].values,
        title: data.data[0].title
      })
    }
  }

  const dataCheckReset = () => {
    setCheckingDataInProcess(false)
    setDataCheckPassed(false)
    const upload = document.getElementById('upload') as HTMLInputElement
    if (upload) {
      upload.value = ''
      startUpload()
    }
    handleDataCheckReset()
  }

  const convertAndCheckData = (data: IDataMapped) => {
    // converting mm/dd/yyyy format to yy/mm
    const newDates: string[] = data.data[0].dates.map((date: string) => {
      if (!date || typeof date !== 'string') {
        dataCheckFailed(
          `One of the dates: ${date} is not in the correct format`
        )
        return ''
      }

      const dashParts = date.split('-')
      const slashParts = date.split('/')

      let parts = dashParts.length > 1 ? dashParts : slashParts

      if (parts.length !== 3 && parts.length !== 2) {
        dataCheckFailed(
          `One of the dates: ${date} is not in the correct format`
        )
        return ''
      }

      if (parts.length === 2) {
        parts = ['01', ...parts.reverse()]
      }

      if (parts[2].length === 2) {
        parts[2] = parseInt(parts[2]) > 30 ? '19' + parts[2] : '20' + parts[2]
      }

      const padDate = (date: string) => {
        return date.length === 1 ? '0' + date : date
      }

      return parts.reverse().map(padDate).join('-')
    })

    data.data[0].dates = newDates
    // Check if data or any of its properties are null or undefined
    if (
      !data ||
      !data.data ||
      !data.data[0] ||
      !data.data[0].dates ||
      !data.data[0].values
    ) {
      dataCheckFailed('Data or any of its properties are null or undefined') // Adding the error message here
      return
    }

    // Check if data.data[0].dates and data.data[0].values have the same length
    if (data.data[0].dates.length !== data.data[0].values.length) {
      dataCheckFailed('Dates and values have different lengths') // Adding the error message here
      return
    }
    const regexPattern = /^\d{4}-\d{2}-\d{2}$/
    for (const date of data.data[0].dates) {
      if (!regexPattern.test(date) && date.toLowerCase() !== 'date') {
        dataCheckFailed('Date format is incorrect') // Adding the error message here
        return
      }
    }

    for (const value of data.data[0].values.slice(data.data[0].dates.length)) {
      if (value === null || value === undefined) {
        dataCheckFailed('One of the values is null or undefined') // Adding the error message here
        return
      }
      const newValue = Number(value)
      if (typeof newValue !== 'number') {
        dataCheckFailed(
          'One of the values is not a number. The value is: ' + newValue
        )
        return
      }
    }
    dataCheckSuccess()
  }

  const readUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    startUpload()
    try {
      if (e.target.files) {
        const file = e.target.files[0]
        if (
          file.type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          uploadFailed()
          dataCheckFailed('File type is not supported') // Adding the error message here
          return
        }

        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const data = e.target!.result as ArrayBuffer
          try {
            const workbook = xlsx.read(data, { type: 'array' })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet, {
              raw: false, // Read formatted values instead of raw values
              dateNF: 'dd-mm-yyyy' // Set the date format as per your input file
            }) as any
            setUploadSuccess(true)

            const dateKey = Object.keys(json[0]).find((key) =>
              key.toLowerCase().includes('date')
            )
            const valueKey = Object.keys(json[0]).find((key) => key !== dateKey)

            const dates = json.map((item: any) => item[dateKey as any])
            const values = json.map((item: any) => item[valueKey as any])
            setData({
              data: [
                {
                  dates,
                  values,
                  title: valueKey as string
                }
              ]
            })
            uploadSuccessful()
            startCheckingData()
          } catch (error) {
            uploadFailed()
          }
        }
        reader.readAsArrayBuffer(file)
      }
    } catch (error) {
      uploadFailed()
    }
  }

  const handleCellChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedRows = [...rows]
    updatedRows[index] = {
      ...updatedRows[index],
      [event.target.name]: event.target.value
    }
    setRows(updatedRows)
  }

  const handlePaste = (e: any) => {
    e.preventDefault()
    const text = e.clipboardData.getData('text')
    // Assuming the pasted data is separated by tabs and new lines
    const rows = text.split('\n').map((row: any) => {
      const [date, value] = row.split('\t')
      return { date: convertExcelDateToHTMLDate(date), value }
    })
    setRows(rows)
  }

  const handleSubmitTableData = () => {
    let dates = rows.map((row) => row.date)
    const values = rows.map((row) =>
      String(row.value === null ? '' : row.value)
        .replaceAll(',', '')
        .replaceAll('$', '')
        // replaceAll the escape \r character with an empty string
        .replaceAll(/\r/g, '')
        .replaceAll(/\n/g, '')
        .trim()
    )

    // find and remove the rows where either date or value is empty
    const emptyRowIndexes: number[] = []
    rows.forEach((row, index) => {
      if (!row.date || !row.value) {
        emptyRowIndexes.push(index)
      }
    })
    emptyRowIndexes.reverse().forEach((index) => {
      dates.splice(index, 1)
      values.splice(index, 1)
    })

    // convert the dates to the expected format
    dates = dates.map((date) => {
      const parts = date.split('-')
      if (parts.length === 3) {
        return parts.reverse().join('-')
      }
      return date
    })

    const res = {
      data: [
        {
          dates,
          values,
          title: 'value'
        }
      ]
    }

    setData(res)
    uploadSuccessful()
    startCheckingData()
  }

  // if there is custom data, set it as the data and the rows visually
  useEffect(() => {
    if (
      customData &&
      customData.dates.length > 0 &&
      customData.values.length > 0
    ) {
      const data = { data: [customData] }
      setData(data)
      setRows(
        customData.dates.map((date, index) => ({
          date: convertExcelDateToHTMLDate(date),
          value: customData.values[index]
        }))
      )
    }
  }, [customData])

  useEffect(() => {
    try {
      if (rows.length === 0) {
        setRows([{ date: '', value: '' }])
        return
      }
      const lastRow = rows[rows.length - 1]
      if (lastRow.date && lastRow.value) {
        setRows([...rows, { date: '', value: '' }])
      }
      handleSubmitTableData()
    } catch (error) {
      console.error(error)
    }
  }, [rows])

  useEffect(() => {
    if (data && checkingDataInProcess && uploadSuccess && !dataCheckPassed) {
      convertAndCheckData(data)
    }
  }, [data, checkingDataInProcess])

  if (mode === 'table') {
    return (
      <div className="col-12 flex gap-2 py-2">
        <table className="table t-small small">
          <thead>
            <tr>
              <th className="m-1">Date (DD/MM/YY)</th>
              <th className="m-1">Value</th>
              <th className="m-1">Remove</th>
            </tr>
          </thead>
          <tbody className="max-h-[300px] overflow-x-hidden overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            {rows.map((row, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="date"
                    name="date"
                    className="m-1"
                    value={row.date}
                    onChange={(e) => handleCellChange(index, e)}
                    onPaste={handlePaste}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="value"
                    className="m-1"
                    value={row.value === null ? '' : row.value}
                    onChange={(e) => handleCellChange(index, e)}
                    onPaste={handlePaste}
                  />
                </td>
                <td>
                  <button
                    className="no-btn icon"
                    onClick={() => {
                      const updatedRows = [...rows]
                      updatedRows.splice(index, 1)
                      setRows(updatedRows)
                    }}
                  >
                    x
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="flex-column gap-2">
      <span className="banner-strip info small default-text">
        Upload an excel document with 2 columns, ensure the dates column is
        titled &apos;date&apos;. Make sure the dates are in one of the following
        formats: dd-mm-YYYY, dd/mm/YYYY, mm-YYYY, mm/YYYY, mm-yy, or mm/yy.
      </span>
      <form className="mb-1">
        <input
          type="file"
          name="upload"
          id="upload"
          className="wider"
          onChange={readUploadFile}
        />
        {/* button to clear the selection */}
      </form>
      <button type="button" className="secondary" onClick={dataCheckReset}>
        Clear
      </button>
    </div>
  )
}
