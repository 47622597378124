import React, { useState } from 'react'
import IndicatorsAndTrendlinesSearch from '../../search/IndicatorsAndTrendlinesSearch'
import { ChartLinesProps } from './types'
import {
  FunctionalButton,
  FunctionalToggle,
  TabbingSwitch
} from '../../helperComponents/_components'
import IndividualChartLine from './IndividualChartLine'
import {
  chartlinesFetcher,
  createMultipleChartLines,
  generateNewScenario
} from '../../utils/fetch'
import { ChartLine } from '../interfaces'
import { generatePossibleTitles } from './utils/functions'
import PollingResults from './PollingResults'
import CorrelationAnalysis from './CorrelationAnalysis'

const ChartLines = (props: ChartLinesProps) => {
  const [selectedLine, setSelectedLine] = useState<string>(
    props.chartLines?.length > 0 ? props.chartLines[0].fid : ''
  )
  const [chartLines, setChartLines] = useState<ChartLine[]>(
    props.chartLines || []
  )
  const [openSection, setOpenSection] = useState('lines')

  const handleSaveChartLines = async () => {
    if (props.readOnly) {
      return false
    }
    const response = await chartlinesFetcher({
      oneOrMany: 'many',
      method: 'put',
      props: chartLines
    })

    return response
  }

  const handleConvertToScenario = async () => {
    const fid = await generateNewScenario({
      scenarioName: `Auto-generated at ${new Date().toLocaleString()}`,
      scenarioOverview: `Scenario generated at ${new Date().toLocaleString()} from the ${props.pageDetails.type} page.`,
      newScenarioPublic: false,
      noRefresh: true
    })

    if (fid) {
      const res2 = await createMultipleChartLines(
        chartLines.map((line) => ({
          ...line,
          source_fid: fid,
          source_type: 'scenario',
          origin_fid: line.origin_fid,
          origin_type: line.origin_type
        }))
      )

      if (res2) {
        window.open(`/app?t=s&i=${fid}`, '_blank')
      }
    }

    return false
  }

  const handleAddLineToChart = async (id: string) => {
    if (props.readOnly) {
      return false
    }
    if (props.pageDetails.type !== 'scenario') {
      return false
    }

    const title = props.allIndicators.find((ind) => ind.fid === id)?.title

    const newLine = await chartlinesFetcher({
      oneOrMany: 'one',
      method: 'post',
      props: {
        origin_fid: id,
        source_type: 'scenario',
        source_fid: props.pageDetails.fid,
        appearance_settings: {
          title
        }
      }
    })

    if (newLine) {
      props.pageDetails.refresh()

      const newChartLines = [...chartLines, newLine]
      setChartLines(newChartLines)

      return true
    }

    return false
  }

  const handleRemoveLineFromChart = async (id: string) => {
    if (props.readOnly) {
      return false
    }

    if (props.pageDetails.type !== 'scenario') {
      return false
    }

    if (chartLines.length === 1) {
      return false
    }

    let matchedChartLine = chartLines.find((line) => line.fid === id)

    if (!matchedChartLine) {
      matchedChartLine = chartLines.find((line) => line.origin_fid === id)
    }

    if (!matchedChartLine) {
      return false
    }

    const response = await chartlinesFetcher({
      oneOrMany: 'one',
      method: 'delete',
      props: matchedChartLine.fid
    })

    if (response) {
      props.pageDetails.refresh()

      const newChartLines = chartLines.filter(
        (line) => line.fid !== matchedChartLine?.fid
      )
      setChartLines(newChartLines)

      return true
    }

    return false
  }

  const updateChartLine = async (newChartLine: ChartLine | string) => {
    if (props.readOnly) {
      return false
    }
    if (typeof newChartLine === 'string') {
      return await handleRemoveLineFromChart(newChartLine)
    }

    const newChartLines = chartLines.map((line) =>
      line.origin_fid === newChartLine.origin_fid ? newChartLine : line
    )

    setChartLines(newChartLines)

    return true
  }

  return (
    <div className="flex col-12 flex-wrap justify-between">
      <div className="col-11 py-1 ps-2 flex gap-2 h-[40px]">
        <FunctionalButton
          disabled={props.readOnly}
          initialButtonState=""
          functionToExecute={handleSaveChartLines}
          className="primary icon scale-90"
          refreshOnComplete={{
            exists: true,
            refreshFunction: props.pageDetails.refresh
          }}
          iconSaveMode
          doesReset
        />
        <FunctionalButton
          initialButtonState="Save as new Scenario"
          functionToExecute={handleConvertToScenario}
          className="secondary inline"
          iconConvert
          doesReset
          combinedButtonTitle
        />
        <FunctionalToggle
          leftTitle="Lines"
          rightTitle="AI"
          disabled={props.readOnly || props.pageDetails.type !== 'scenario'}
          state={openSection === 'ai'}
          functionToExecute={() =>
            setOpenSection(openSection === 'ai' ? 'lines' : 'ai')
          }
        />
      </div>
      <div
        className="col-12 p-2 ps-1 pb-0"
        style={{ display: openSection !== 'ai' ? 'flex' : 'none' }}
      >
        <CorrelationAnalysis
          chartLines={chartLines}
          disabled={props.readOnly || props.pageDetails.type !== 'scenario'}
        />
      </div>
      <div
        className="col-12 p-2 ps-1"
        style={{ display: openSection !== 'ai' ? 'flex' : 'none' }}
      >
        <IndicatorsAndTrendlinesSearch
          disabled={props.pageDetails.type !== 'scenario' || props.readOnly}
          indicators={props.allIndicators}
          includedIndicators={chartLines.map((line) => line.origin_fid)}
          refreshIndicators={() => {}}
          direction="down"
          hoverMode
          customMessage="Add Lines to Chart"
          addFunction={handleAddLineToChart}
          removeFunction={handleRemoveLineFromChart}
          altFunction={async (id: string) => {}}
        />
      </div>
      {props.readOnly &&
        (props.pageDetails.type === 'scenario' ||
          props.pageDetails.type === 'calculated') && (
          <span className="banner-strip small warning mx-3">Read Only</span>
        )}
      {props.pageDetails.type === 'scenario' && (
        <div
          style={{ display: openSection === 'ai' ? 'flex' : 'none' }}
          className="p-3 col-12"
        >
          <PollingResults scenarioId={props.pageDetails.fid} />
        </div>
      )}

      <div
        className="flex col-12 p-2"
        style={{ display: openSection === 'lines' ? 'flex' : 'none' }}
      >
        <TabbingSwitch
          numberVisible={4}
          className="col-12"
          fit
          options={
            chartLines.map((line, index) => ({
              label: generatePossibleTitles(line.appearance_settings.title)[1],
              // label: `Line ${index + 1}`,
              active: selectedLine === line.fid,
              onClick: () => setSelectedLine(line.fid)
            })) || []
          }
        />
        <hr />
        <div className="col-12 p-2 collapsible-side-panel-body">
          {chartLines.map((line, index) => (
            <div
              className="col-12 my-2 gap-2 flex-column"
              style={{
                display: selectedLine === line.fid ? 'block' : 'none'
              }}
              key={line.fid + '_' + index}
            >
              <IndividualChartLine
                {...props}
                chartLine={line}
                updateChartLine={(line) => updateChartLine(line)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChartLines
