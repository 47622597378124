import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import {
  AccessDenied,
  FunctionalButton,
  Spinner,
  TabbingSwitch
} from '../helperComponents/_components'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  IDataMapped,
  IMiniScenario,
  IScenarioItem,
  ITrendline
} from '../utils/interfaces'
import { getAllValuesGroupedByKey } from '../utils/transformingData'
import { fetchChosenScenario } from '../utils/fetch'
import { retrieveFromCache, saveDataToCache } from '../utils/functions'
import DataTableValues from '../helperComponents/DataTableValues'

const MiniScenario = ({ fid }: IMiniScenario) => {
  try {
    const [loading, setLoading] = useState<boolean>(false)
    const [fetchedAllIndicators, setFetchedAllIndicators] = useState(
      [] as (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    )
    const [fullDates, setFullDates] = useState<string[]>([])
    const [fetchedData, setFetchedData] = useState<IDataMapped>()
    const [scenarioDetails, setScenarioDetails] = useState<IScenarioItem>()
    const fetchedRawScenarioData = useRef<any>()
    const [lastUpdatedScenario, setLastUpdatedScenario] = useState<Date>()
    const [selectedScenarioTab, setSelectedScenarioTab] =
      useState('googleChart')

    const handleScenarioTabChange = (option: any) => {
      setSelectedScenarioTab(option)
    }

    const resetModals = (all?: boolean) => {
      // Implementation here
    }

    const resetScenarioPage = () => {
      resetModals(true)
      setFetchedData(undefined)
    }

    const fetchScenario = useCallback(
      async (fid: string, noReload?: boolean, useCache?: boolean) => {
        if (!noReload) {
          resetScenarioPage()
          setLoading(true)
        }

        try {
          let result = null

          if (useCache) {
            const EXPIRY = 1000 * 60 * 60 * 24
            const data = retrieveFromCache(fid, EXPIRY)

            if (data) {
              result = data.data as any
              setLastUpdatedScenario(new Date(data.date))
            }
          }

          if (result === null) {
            const fetchResult = await fetchChosenScenario(fid)
            result = fetchResult as any
            const now = new Date()
            saveDataToCache(fid, result, now)
            setLastUpdatedScenario(now)
          }

          const {
            fetchedScenarioData,
            fetchedScenarioDetails,
            completeDates,
            allIndicators
          } = result as any

          const transformed = getAllValuesGroupedByKey(fetchedScenarioData)

          setScenarioDetails(fetchedScenarioDetails)
          setFetchedAllIndicators(allIndicators)
          setFullDates(completeDates)
          fetchedRawScenarioData.current = fetchedScenarioData
          setFetchedData(transformed)

          setLoading(false)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
      },
      [scenarioDetails?.fid]
    )

    useEffect(() => {
      if (!loading) {
        fetchScenario(fid, false, true)
      }
    }, [fid])

    if (loading) {
      return (
        <Spinner
          options={{
            noMessage: true,
            noSpinner: true
          }}
        />
      )
    }

    if (fetchedData && scenarioDetails) {
      return (
        <Fragment>
          <div className="entity-parameters">
            <span className="small">
              Last updated:{' '}
              {lastUpdatedScenario
                ? lastUpdatedScenario.toLocaleString()
                : new Date().toLocaleString()}
            </span>
            <FunctionalButton
              initialButtonState="Refresh"
              functionToExecute={() => fetchScenario(fid, false, false)}
              className="no-btn no-hover"
              iconReload
              doesReset
            />
          </div>
          <TabbingSwitch
            options={[
              {
                label: 'Chart',
                active: selectedScenarioTab === 'googleChart',
                onClick: () => handleScenarioTabChange('googleChart')
              },
              {
                label: 'Table',
                active: selectedScenarioTab === 'dataTable',
                onClick: () => handleScenarioTabChange('dataTable')
              },
              {
                label: 'Details',
                active: selectedScenarioTab === 'details',
                onClick: () => handleScenarioTabChange('details')
              }
            ]}
            fit
          />
          {selectedScenarioTab === 'googleChart' && (
            <HighChartsLinearChart
              pageDetails={{
                type: 'scenario',
                title: scenarioDetails.scenario_name,
                fid: scenarioDetails.fid,
                refresh: () => fetchScenario(fid, false, false)
              }}
              page="scenario"
              chartOptions={{
                xAxisTitle: 'Month',
                yAxisTitle: 'Value',
                title: scenarioDetails.scenario_name,
                mediumSize: false
                // reactive: initialChartKeys !== entityChartKeys
              }}
              chartData={{
                fetchedData: fetchedRawScenarioData.current,
                filteredKeys: fetchedData.data.map((item: any) => item.title),
                titles: fetchedData.data.map((item: any) => ({
                  [item.title]:
                    fetchedAllIndicators.find(
                      (indicator: any) => indicator.fid === item.title
                    )?.title || item.title
                })),
                fullDates,
                deviations: fetchedRawScenarioData.current.map((item: any) =>
                  fetchedAllIndicators.find(
                    (indicator: any) => indicator.fid === item.title
                  )
                ) as any
              }}
              componentOptions={{
                // retrieveIndicatorZones,
                own: scenarioDetails && !scenarioDetails.admin_access,
                parametersFetched: scenarioDetails,
                height: Math.max(window.innerHeight * 0.45, 400)
              }}
            />
          )}

          <div
            className="entity-data"
            style={{
              display: selectedScenarioTab === 'dataTable' ? 'block' : 'none'
            }}
          >
            <DataTableValues
              data={fetchedRawScenarioData.current}
              parameters={fetchedAllIndicators}
            />
          </div>
          <div
            style={{
              display: selectedScenarioTab === 'details' ? 'block' : 'none'
            }}
          >
            <div className="p-2 flex gap-3">
              <h2 className="m-0">{scenarioDetails.scenario_name}</h2>
              <button
                onClick={() =>
                  window.switchFunctions.scenario(scenarioDetails.fid)
                }
                className="no-btn no-hover border wide"
              >
                Visit Scenario
              </button>
            </div>
            <p className="px-2">{scenarioDetails.scenario_overview}</p>
          </div>
        </Fragment>
      )
    }
    return null
  } catch (error) {
    console.error('Error rendering scenario page:', error)
    return <AccessDenied />
  }
}

export default MiniScenario
