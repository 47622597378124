import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  modifyCollectionEntries,
  deleteIndicatorByName,
  fetchChosenIndicator,
  updateIndicarorDetails,
  updateRangeSelected,
  updateEntityInverse,
  createNewForecast,
  updateDbIndicator,
  fetchExtraData,
  refreshExternalData
  // updateIndicatorDataMode
} from '../utils/fetch'
import {
  // ICollection,
  ICollectionEntry,
  IBasicIndicator,
  ITrendline,
  IEntityAllKeys,
  IIndicatorPage,
  ICalculatedIndicator,
  IMappedDataEntry,
  IDataMapped,
  IForecast,
  IIncludedId,
  IFetchedDataEntry,
  IIndicator
  // IRow
} from '../utils/interfaces'
import PopupModal from '../helperComponents/PopUpModal'
import {
  AccessDenied,
  FunctionalButton,
  Spinner,
  EntityHeader,
  AnalyseWithAI
  // FunctionalDropdown
  // FunctionalToggle
} from '../helperComponents/_components'
import {
  Icon3Dots // IconCollapse,
  // IconExpand
  // IconExpandMore
} from '../helperComponents/Icons'
import {
  addToVisitedPages,
  evaluateLocalStoragePageMode,
  generateRandomId,
  findMatchPercentage,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
// import DataMapped from '../helperComponents/DataMapped'
import {
  convertExcelDateToHTMLDate,
  exportToExcel,
  getAllValuesGroupedByKey
} from '../utils/transformingData'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import UploadPage from '../helperComponents/UploadPage'

import RangeSlider2 from '../helperComponents/RangeSlider2'
import { Trendlines } from '../trendlines/Trendlines'
import CreateTrendline from '../trendlines/CreateTrendline'
import PopupModalNew from '../helperComponents/PopUpModalNew'
import RenameMajorEntity from '../helperComponents/RenameMajorEntity'
import { ChartLine } from '../charts/interfaces'
import DataEntityParameters from '../helperComponents/DataEntityParameters'
// import DataTableParameters from '../helperComponents/DataTableParameters'
import DataTableValues from '../helperComponents/DataTableValues'

const IndicatorPage = ({
  indicatorId,
  userTags,
  readonly,
  favourites
}: IIndicatorPage) => {
  try {
    // const [selectedDataMappedMode, setSelectedDataMappedMode] = useState<
    //   'value' | 'arrow' | 'off'
    // >(window.globalSettings.rises_and_drops_in_sd.state)
    const [pageMode, setPageMode] = useState<
      'basic' | 'trendline' | 'forecast'
    >('basic')
    const fetchedIndicatorParameters = useRef<IBasicIndicator>(
      {} as IBasicIndicator
    )
    const [chartLines, setChartLines] = useState<ChartLine[]>([])
    // const [fetchedTrendlines, setFetchedTrendlines] = React.useState<
    //   IBasicIndicator[]
    // >([])
    const [
      fullFetchedIndicatorsParameters,
      setFullFetchedIndicatorsParameters
    ] = React.useState<ITrendline[]>([])
    // const [indicatorDataMode, setIndicatorDataMode] = useState<string>('')
    const files = useRef<File[] | null>(null)
    // eslint-disable-next-line
    const fetchedAllIndicators = useRef<
      (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    >([])

    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })
    const [trendlinesFetched, setTrendlinesFetched] = useState<boolean>(false)
    const [modalIndicatorEditData, setModalIndicatorEditData] =
      React.useState(false)
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const fetchedIndicatorData = useRef<IFetchedDataEntry[]>([])
    const [indicatorDataForAI, setIndicatorDataForAI] = useState<any>(null)

    const fetchedDataTransformed = useRef({} as IDataMapped)

    const fullDates = useRef<string[]>([])
    const [readOnly, setReadOnly] = useState<boolean>(readonly)
    // const [dataMappedCellWidth, setDataMappedCellWidth] = useState<number>(50)
    const [pageLoading, setPageLoading] = React.useState(false)
    const [modalDeleteIndicator, setModalDeleteIndicator] =
      React.useState(false)
    const defaultContextMenuSettings = {
      top: 0,
      left: 0,
      show: false
    }

    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings
    )
    const [
      modalIndicatorNameAndDescription,
      setModalIndicatorNameAndDescription
    ] = useState(false)

    const [selectedDataBoundaries, setSelectedDataBoundaries] = useState<
      string[]
    >([])
    // const [dataSectionCollapsed, setDataSectionCollapsed] =
    //   useState<boolean>(false)

    const [includedIds, setIncludedIds] = useState<IIncludedId[]>([])

    // const [modalChartExpanded, setModalChartExpanded] = useState<boolean>(true)
    const [modalIndicatorRange, setModalIndicatorRange] =
      useState<boolean>(false)
    const [dateRangeSliderChanged, setDateRangeSliderChanged] =
      useState<boolean>(false)

    // const [fetchedCollections, setFetchedCollections] = useState<ICollection[]>(
    //   []
    // )
    const [isCurrentIndicatorFavourite, setIsCurrentIndicatorFavourite] =
      useState(false)

    const resetContextMenuDetails = () => {
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
      }
    }

    const resetModals = () => {
      window.collapseSidePanel(true)
      setModalDeleteIndicator(false)
      setModalIndicatorNameAndDescription(false)
      localStorage.removeItem('openPopupId')
      setContextMenuDetails(defaultContextMenuSettings)
      // setDataSectionCollapsed(false);
      setModalIndicatorEditData(false)
      setModalIndicatorRange(false)
    }

    const handleContextMenuDetails = (e: any) => {
      e.preventDefault()
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
        return
      }
      // resetModals()
      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX + 10
      })
    }

    const checkIfIndicatorIsFavourite = () => {
      if (!favourites || favourites.indicators.length === 0) {
        return false
      }

      return favourites.indicators.some(
        (item: ICollectionEntry) => item.id === indicatorId
      )
    }

    const fetchIndicator = async (indicatorId: string, noClose?: boolean) => {
      try {
        setTrendlinesFetched(false)
        const key = `indicatorPageChart-${window.location.search}`
        localStorage.removeItem(key)
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }
        const response = await fetchChosenIndicator(indicatorId)
        if (!response) {
          setPageLoading(false)
          setAccessAllowed(false)
          return
        }
        const fetchedParameters: IBasicIndicator =
          response.fetchedIndicatorParameters[0]
        const { trendlines, forecasts } = response
        const allParams = [
          ...response.fetchedIndicatorParameters,
          ...trendlines,
          ...forecasts.parameters
        ]

        // if frequency is monthly, we need to remove all but the first day of the month in each month in fulldates
        // const actualFullDates = response.fullDates.filter(
        //   (date: string, index: number) =>
        //     frequency === 'monthly'
        //       ? date.split('T')[0].split('-')[2] === '01'
        //       : false
        // )

        const transformed = getAllValuesGroupedByKey(
          response.fetchedIndicatorData
        )

        const transformedRange =
          transformFetchedRangedDetailsScenariotoRangeInputs(
            fetchedParameters.range_chosen
          )
        const isFavourite = checkIfIndicatorIsFavourite()
        const idsIncluded =
          fetchedParameters.ids_included &&
          fetchedParameters.ids_included.length > 0
            ? fetchedParameters.ids_included
            : []

        setIncludedIds(idsIncluded)

        fetchedAllIndicators.current = response.allIndicators
        fetchedIndicatorParameters.current = fetchedParameters
        // setIndicatorDataMode(fetchedParameters.data_mode)
        fetchedIndicatorData.current = response.fetchedIndicatorData
        fullDates.current = response.fullDates
        setChartLines(response.chartLines)
        setReadOnly(!fetchedParameters.is_own)
        setFullFetchedIndicatorsParameters(allParams)
        setOwnerInfo({
          profileImage: response.owner.profile_image,
          username: response.owner.username
        })
        setSelectedDataBoundaries(transformedRange)
        fetchedDataTransformed.current = transformed
        setIsCurrentIndicatorFavourite(isFavourite)

        const dataForAI = {
          name: fetchedParameters.title,
          description: fetchedParameters.meaning,
          category: fetchedParameters.category,
          short_description: fetchedParameters.short_description,
          data: transformed.data[0].dates.reduce(
            (acc: any, date: string, index: number) => {
              const trimmedDate = date.slice(0, 10)
              acc[trimmedDate] = transformed.data[0].values[index]
              return acc
            },
            {}
          )
        }
        setIndicatorDataForAI(dataForAI)

        if (!noClose) {
          setPageLoading(false)
        }
        setAccessAllowed(true)
      } catch (err: any) {
        console.error(err)
        window.switchFunctions.home()
      }
    }

    const fetchTrendlines = async (force = false) => {
      const keys = Object.keys(fetchedIndicatorData.current[0]).filter(
        (key) => key !== 'date' && key !== 'id' && key !== indicatorId
      )

      const filteredKeys = includedIds
        .filter((key) => key.type === 'trendline')
        .map((key) => key.fid)

      const trendlinesIds = filteredKeys
        .map((key) => [`${key} Value`, `${key} Deviation`])
        .flat()
      const match = findMatchPercentage(keys, trendlinesIds)

      if ((match > 50 || trendlinesFetched) && !force) {
        setTrendlinesFetched(true)
        return
      }
      setPageLoading(true)
      let extrasToFetch =
        fullFetchedIndicatorsParameters.filter(
          (item) => item.type === 'trendline' && filteredKeys.includes(item.fid)
        ) || []
      if (extrasToFetch.length === 0) {
        extrasToFetch = includedIds.map(
          (item) =>
            ({
              fid: item.fid,
              frequency: fetchedIndicatorParameters.current.frequency,
              type: item.type
            }) as ITrendline
        )
      }

      if (extrasToFetch.length === 0) {
        const selected =
          fetchedIndicatorParameters.current.chart_columns_selected

        extrasToFetch = selected.split(', ').map(
          (item) =>
            ({
              fid: item,
              frequency: fetchedIndicatorParameters.current.frequency
            }) as ITrendline
        )
      }

      // extrasToFetch = extrasToFetch.map((item) => item.fid)
      const additionalData = [
        ...extrasToFetch,
        fetchedIndicatorParameters.current
      ]
      const data = await fetchExtraData(
        additionalData,
        fetchedIndicatorParameters.current.frequency
      )

      fetchedIndicatorData.current = data
      setPageLoading(false)
    }

    const handleNewIndicator = async (
      indicatorId: string,
      noClose?: boolean
    ) => {
      if (pageLoading) return
      if (indicatorId) {
        await fetchIndicator(indicatorId, noClose)
      }
      const pageMode = evaluateLocalStoragePageMode(indicatorId)
      if (pageMode === 'trendline') {
        await fetchTrendlines(true)
      } else {
        setTrendlinesFetched(false)
      }
      setPageMode(pageMode)
    }

    const refreshIndicator = async () => {
      if (pageLoading) return

      await handleNewIndicator(indicatorId)
    }

    const refreshIndicatorNoClose = (contextMenu?: boolean) => {
      if (pageLoading) return
      handleNewIndicator(indicatorId, true).then(() => {
        if (contextMenu) {
          // setTimeout(() => {
          resetContextMenuDetails()
          // }, 500)
        }
      })
    }

    const handleSwitchPageMode = async (
      selectedOption: 'basic' | 'trendline' | 'forecast'
    ) => {
      resetModals()
      if (
        selectedOption === 'trendline' &&
        fetchedIndicatorData.current.length
      ) {
        await fetchTrendlines()
      }

      if (selectedOption === 'basic') {
        // ensure the indicator key is included in the chart keys and data keys
        const indicatorKey = includedIds.find(
          (item) => item.fid === indicatorId
        )

        setTrendlinesFetched(false)

        if (indicatorKey) {
          setIncludedIds([
            ...includedIds.filter((item) => item.fid !== indicatorId),
            {
              fid: indicatorId,
              chart: true,
              data: true,
              type: 'indicator'
            }
          ])
        }
        addToVisitedPages({
          fid: indicatorId,
          type: 'indicator',
          pageMode: selectedOption
        })
      } else {
        addToVisitedPages({
          fid: indicatorId,
          type: 'indicatorTrendlines',
          pageMode: selectedOption
        })
      }

      localStorage.setItem(
        'indicatorPageMode',
        `${selectedOption}---${indicatorId}`
      )
      setPageMode(selectedOption)

      return true
    }

    // const addIndicatorToShown = async (fid: string) => {
    //   const res = await updateSelectedColumnsEntity({
    //     selectedChartList: [
    //       ...entityChartKeys.map((item) => item.title),
    //       fid
    //     ].join(', '),
    //     selectedDataList: [
    //       ...entityChartKeys.map((item) => item.title),
    //       fid
    //     ].join(', '),
    //     entityId: indicatorId,
    //     entityType: 'indicator'
    //   })

    //   if (res) {
    //     return fid
    //   }
    // }

    const applyRangeChange = async (lowerValue: string, upperValue: string) => {
      const result = await updateRangeSelected({
        rangeSelected: `[${lowerValue},${upperValue})`,
        entityId: indicatorId,
        entityType: 'indicator'
      })

      if (result) {
        refreshIndicatorNoClose()
      }

      return result
    }

    const handleRename = async ({
      newName,
      newDescription,
      newShortDescription
    }: any) => {
      const res = await updateIndicarorDetails({
        indicatorId,
        newName,
        newDescription,
        newShortDescription
      })

      if (res) {
        setModalIndicatorNameAndDescription(false)
        refreshIndicator()
      }

      return res
    }

    // const applyMappedCellWidthChange = (e: any) => {
    //   if (
    //     e.target.value >= 20 &&
    //     e.target.value <= 150 &&
    //     e.target.value.toString().slice(0, 1) !== '0' &&
    //     !isNaN(e.target.value)
    //   ) {
    //     const value = e.target.value
    //     setDataMappedCellWidth(value)
    //     const tickWidth = 17 * (value / 50)

    //     // Assuming 'value' and 'tickWidth' are defined and have numeric types
    //     // 1. Set width for elements with classes .date-tick and .tick
    //     const ticks: any = document.querySelectorAll('.date-tick, .tick')
    //     ticks.forEach(
    //       (tick: { style: { width: string } }) =>
    //         (tick.style.width = `${value}px`)
    //     )

    //     // 2. Set min-width for elements with classes .date-tick and .tick
    //     ticks.forEach(
    //       (tick: { style: { minWidth: string } }) =>
    //         (tick.style.minWidth = `${value}px`)
    //     )

    //     // 3. Set padding-left to 0 for elements with the selector .ticks-entry .ticks span
    //     const spanElements: any = document.querySelectorAll(
    //       '.ticks-entry .ticks span'
    //     )
    //     spanElements.forEach(
    //       (span: { style: { paddingLeft: string } }) =>
    //         (span.style.paddingLeft = '0')
    //     )

    //     // 4. Set width for images inside elements with the class .tick
    //     const tickImages: any = document.querySelectorAll('.tick img')
    //     tickImages.forEach(
    //       (img: any) =>
    //         (img.style.width = `${tickWidth > 17 ? 17 : tickWidth}px`)
    //     )
    //   }
    // }

    const handleExportData = () => {
      try {
        if (
          !fetchedDataTransformed ||
          !fetchedDataTransformed.current.data ||
          !fetchedDataTransformed.current.data[0]
        ) {
          return false
        }

        const dates = fetchedDataTransformed.current.data[0].dates
        const formattedDates = dates.map((date: string) => {
          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        })

        const values = fetchedDataTransformed.current.data[0].values
        const data = values.map((item: any, index: number) => ({
          date: formattedDates[index],
          [fetchedIndicatorParameters.current.title]: item
        }))

        exportToExcel(
          data,
          `${fetchedIndicatorParameters.current.title} - ${new Date()
            .toLocaleDateString()
            .split('/')
            .join('-')
            .slice(0, 10)}`
        )

        return true
      } catch (err: any) {
        console.error(err)
      }
      return false
    }

    const handleCreateForecast = async () => {
      const res = await createNewForecast(indicatorId)

      if (!res) {
        return false
      }

      // res = await addIndicatorToShown(res)

      window.switchFunctions.forecast(res)
      return true
    }

    const handleUpdateData = async (data: IMappedDataEntry) => {
      try {
        const res = await updateDbIndicator({
          indicatorName: fetchedIndicatorParameters.current.fid,
          replace: true,
          data: {
            dates: data.dates,
            data: data.values
          }
        })

        if (res) {
          refreshIndicatorNoClose()
        }

        return res
      } catch (error) {
        console.error('Error updating data:', error)
        return false
      }
    }

    const handleCurrentPageIndexChange = async (index: number) => {
      const currentPage = window.visitedPages[index - 1]
      if (
        currentPage.pageMode === 'trendline' &&
        fetchedIndicatorData.current.length
      ) {
        await fetchTrendlines()
      }
      if (
        currentPage.fid === indicatorId &&
        currentPage.pageMode !== pageMode
      ) {
        setPageMode((currentPage.pageMode as any) || 'basic')
      }
    }

    useEffect(() => {
      handleNewIndicator(indicatorId)
    }, [indicatorId])

    useEffect(() => {
      handleCurrentPageIndexChange(window.currentPageIndex)
    }, [window.currentPageIndex])

    if (pageLoading) return <Spinner />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (accessAllowed && !pageLoading && fetchedIndicatorParameters) {
      return (
        <div className="entity indicator">
          <EntityHeader
            breadcrumbs={[
              {
                title: 'Indicator',
                onClick: () => handleSwitchPageMode('basic'),
                active: pageMode === 'basic'
              },
              ...(pageMode === 'trendline'
                ? [
                    {
                      title: 'Trendlines Analysis',
                      onClick: () => handleSwitchPageMode('trendline'),
                      active: pageMode === 'trendline'
                    }
                  ]
                : [])
            ]}
            titleClick={() => window.switchFunctions.indicator(indicatorId)}
            subtitle="Indicator"
            ownerInfo={ownerInfo}
            title={fetchedIndicatorParameters.current.title}
            description={fetchedIndicatorParameters.current.short_description}
          />

          <div className="entity-blocks">
            <div className="container w-12">
              <div className="entity-parameters justify-content-start">
                {!readOnly && pageMode === 'trendline' && (
                  <PopupModalNew
                    title="Create Trendline"
                    size="largest"
                    fid={generateRandomId()}
                    handleSubmit={() => undefined}
                    saveButtonExists={false}
                    inner
                    fullHeight
                    noChanges
                    className="p-0"
                    buttonFragment={{
                      props: {
                        className:
                          'inline no-btn default-text no-hover no-border m-2',
                        functionToExecute: () => undefined,
                        noReturn: true,
                        doesReset: true,
                        initialButtonState: 'Generate Trendline',
                        iconPlusMode: true
                      }
                    }}
                  >
                    <CreateTrendline
                      fetchedDataTransformed={
                        fetchedDataTransformed.current.data.filter(
                          (item) =>
                            item.title ===
                            fetchedIndicatorParameters.current.fid
                        )[0]
                      }
                      fetchedIndicatorParameters={
                        fetchedIndicatorParameters.current
                      }
                      indicatorId={indicatorId}
                      fullDates={fullDates.current}
                      source="indicator"
                      source_fid={indicatorId}
                      closeModal={resetModals}
                    />
                  </PopupModalNew>
                )}

                {!readOnly && pageMode !== 'trendline' && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    fakeLoading
                    noReturn
                    functionToExecute={() => handleSwitchPageMode('trendline')}
                    doesReset
                    initialButtonState={'Trendlines Analysis'}
                    iconTrendline
                  />
                )}
                {!readOnly && pageMode === 'basic' && (
                  <AnalyseWithAI
                    analyseParameters={indicatorDataForAI}
                    files={files}
                    analysisMode="component"
                    instructions="Examine the document detailing the indicator and focus on the values over different time periods, identifying any significant trends or changes. Highlight key moments where the values experienced notable declines, explaining each drop by comparing it to previous data and evaluating its significance. Provide potential causes for each decline, considering factors such as market events, external influences, or internal system changes. Based on the patterns and historical data, assess possible future movements of the values and explain why certain trends may continue, reverse, or stabilize. Finally, summarize the analysis and mention other areas where you can assist."
                  />
                )}
                {!readOnly && pageMode === 'forecast' && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border m-2"
                    functionToExecute={handleCreateForecast}
                    doesReset
                    initialButtonState={'Generate Forecast'}
                    iconPlusMode
                  />
                )}
                {pageMode === 'basic' && (
                  <button
                    className="no-btn flex no-hover"
                    onClick={handleContextMenuDetails}
                  >
                    <Icon3Dots />
                  </button>
                )}
              </div>
            </div>
            {pageMode === 'basic' && (
              <Fragment>
                <div className="container w-6 large scroll no-scrollbar">
                  <div className="entity-chart">
                    <HighChartsLinearChart
                      pageDetails={{
                        type: 'indicator',
                        title: fetchedIndicatorParameters.current.title,
                        fid: indicatorId,
                        refresh: refreshIndicatorNoClose
                      }}
                      readOnly={readOnly}
                      page="indicator"
                      allIndicators={fetchedAllIndicators.current}
                      chartOptions={{
                        xAxisTitle: 'Month',
                        yAxisTitle: 'Value',
                        title: fetchedIndicatorParameters.current.title,
                        reactive: true,
                        mediumSize: true
                        // reactive: initialChartKeys !== entityChartKeys
                      }}
                      chartData={{
                        fetchedData: fetchedIndicatorData.current,
                        fullDates: fullDates.current,
                        chartLines,
                        titles: [
                          {
                            [indicatorId]:
                              fetchedIndicatorParameters.current.title
                          }
                        ],
                        filteredKeys: [indicatorId]
                        // deviations: fullFetchedIndicatorsParameters
                      }}
                      componentOptions={{
                        own:
                          !readOnly &&
                          fetchedIndicatorParameters &&
                          !fetchedIndicatorParameters.current.admin_access,
                        parametersFetched: fetchedIndicatorParameters.current
                      }}
                    />
                  </div>
                </div>
                <div
                  className="container w-6 large no-scrollbar"
                  onClick={resetContextMenuDetails}
                >
                  <div className="entity-data no-scrollbar">
                    <DataEntityParameters
                      page="indicator"
                      readOnly={readOnly}
                      refreshFunction={refreshIndicator}
                      fid={indicatorId}
                      fetchedIndicatorParameters={
                        fetchedIndicatorParameters.current
                      }
                      fetchedDataTransformed={fetchedDataTransformed.current}
                      userTags={userTags}
                      refreshEntityNoClose={refreshIndicatorNoClose}
                      handleUpdateData={handleUpdateData}
                      handleExportData={handleExportData}
                      files={files.current}
                      aiInstructions="Examine the document detailing the indicator and focus on the values over different time periods, identifying any significant trends or changes. Highlight key moments where the values experienced notable declines, explaining each drop by comparing it to previous data and evaluating its significance. Provide potential causes for each decline, considering factors such as market events, external influences, or internal system changes. Based on the patterns and historical data, assess possible future movements of the values and explain why certain trends may continue, reverse, or stabilize. Finally, summarize the analysis and mention other areas where you can assist."
                    />
                  </div>
                </div>
                <div
                  className="container w-12 fit"
                  onClick={resetContextMenuDetails}
                >
                  {/* <div className="container w-6 scroll-x no-borders">
                    <DataTableParameters
                      indicators={fullFetchedIndicatorsParameters}
                    />
                  </div> */}
                  <div className="container w-6 fit scroll-x">
                    <DataTableValues
                      data={fetchedIndicatorData.current}
                      parameters={
                        [fetchedIndicatorParameters.current] as IIndicator[]
                      }
                    />
                  </div>
                </div>
              </Fragment>
            )}
            {pageMode === 'trendline' && (
              <Trendlines
                functions={{}}
                entityChartKeys={
                  includedIds
                    .filter((key) => key.chart)
                    .filter(
                      (key) =>
                        key.type === 'trendline' || key.fid === indicatorId
                    )
                    .map((key) => ({
                      title: key.fid,
                      type: key.type
                    })) as IEntityAllKeys[]
                }
                fullDates={fullDates.current}
                forecastsAssosiated={
                  fetchedAllIndicators.current.filter(
                    (item) => item.type === 'forecast'
                  ) as IForecast[]
                }
                selectedDataBoundaries={selectedDataBoundaries}
                data={fetchedIndicatorData.current}
                trendlines={fullFetchedIndicatorsParameters.filter(
                  (item) => item.type === 'trendline'
                )}
                actuals={fullFetchedIndicatorsParameters.filter((item) =>
                  includedIds
                    .filter((key) => key.chart)
                    .filter(
                      (key) =>
                        key.type === 'actuals' || key.type === 'indicator'
                    )
                    .map((key) => key.fid)
                    .includes(item.fid)
                )}
                readOnly={readOnly}
                userTags={userTags}
                allIndicators={fetchedAllIndicators.current}
                indicatorPageDependent={true}
              />
            )}
          </div>
          <CustomContextMenu
            contextMenuSettings={contextMenuDetails}
            menu={[
              {
                onClick: async () => await refreshExternalData(indicatorId),
                title: 'Refresh Data',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                },
                noAccess:
                  readOnly ||
                  fetchedIndicatorParameters.current.category !== 'external'
              },
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorNameAndDescription(true)
                },
                title: 'Edit Details',
                noAccess: readOnly
              },
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorEditData(true)
                },
                title: 'Edit Data',
                noAccess: readOnly
              },
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorRange(true)
                },
                noAccess: readOnly,
                title: 'Edit Range'
              },
              {
                onClick: () => {
                  resetModals()
                  window.switchFunctions.upload()
                },
                title: 'Create/Clone Indicator',
                noAccess: readOnly
              },
              {
                onClick: () =>
                  modifyCollectionEntries({
                    entityType: 'indicator',
                    entityId: indicatorId,
                    action: isCurrentIndicatorFavourite ? 'remove' : 'add',
                    entityName: fetchedIndicatorParameters.current.title,
                    collectionId: favourites === undefined ? 0 : favourites.id
                  }),
                title: isCurrentIndicatorFavourite
                  ? 'Remove from My Library'
                  : 'Add to My Library',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                },
                noAccess: fetchedIndicatorParameters.current.is_own
              },

              // {pageMode !== 'basic' && (
              //   <FunctionalButton
              //     iconBackwardsMode
              //     initialButtonState={'Back'}
              //     combinedButtonTitle
              //     className="teriary wide"
              //     functionToExecute={() => handleSwitchPageMode('basic')}
              //     noReturn
              //   />
              // )}
              {
                title: 'Forecasts Analysis',
                onClick: () => handleSwitchPageMode('forecast'),
                noAccess: !fetchedIndicatorParameters.current.is_own || readOnly
              },
              {
                onClick: async () =>
                  await updateEntityInverse({
                    fid: fetchedIndicatorParameters.current.fid,
                    type: 'indicator'
                  }),
                title: fetchedIndicatorParameters.current.inverse
                  ? 'Toggle Not Inverse'
                  : 'Toggle Inverse',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                },
                noAccess: readOnly
              },
              {
                onClick: () => {
                  resetModals()
                  setModalDeleteIndicator(true)
                },
                title: 'Delete',
                noAccess: readOnly
              }
            ]}
          />

          {modalIndicatorEditData && !readOnly && (
            <PopupModal
              isOpen={modalIndicatorEditData}
              onClose={() => setModalIndicatorEditData(false)}
              title="Edit Indicator Details"
              size={'large'}
              handleSubmit={() => undefined}
              saveButtonExists={false}
              noChanges={true}
            >
              <UploadPage
                fullFetchedIndicatorsParameters={[
                  fetchedIndicatorParameters.current
                ]}
                refreshFunction={refreshIndicator}
                singleIndicatorMode={{
                  exists: true,
                  indicatorId
                }}
                mode="table"
                customData={(() => {
                  const data = fetchedDataTransformed.current.data.find(
                    (item: IMappedDataEntry) =>
                      item.title === fetchedIndicatorParameters.current.fid
                  )
                  if (!data) return { dates: [], values: [], title: '' }

                  return {
                    dates: data.dates.map((date: string) =>
                      convertExcelDateToHTMLDate(date)
                    ),
                    values: data.values,
                    title: data.title
                  }
                })()}
                customPostFunction={handleUpdateData}
              />
            </PopupModal>
          )}
          {modalDeleteIndicator && (
            <PopupModal
              isOpen={modalDeleteIndicator && !readOnly}
              onClose={() => setModalDeleteIndicator(false)}
              title="Are you sure you want to delete this indicator?"
              size="small"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              inner
            >
              <div className="flex-row center middle margin-1">
                <FunctionalButton
                  className="destructive"
                  functionToExecute={async () =>
                    await deleteIndicatorByName(indicatorId)
                  }
                  initialButtonState={'Delete'}
                  refreshOnComplete={{
                    exists: true,
                    refreshFunction: () =>
                      window.switchFunctions.deleted(indicatorId)
                  }}
                />
              </div>
            </PopupModal>
          )}

          {modalIndicatorNameAndDescription && (
            <PopupModal
              isOpen={modalIndicatorNameAndDescription && !readOnly}
              onClose={resetModals}
              title="Edit Indicator Details"
              size="large"
              handleSubmit={() => undefined}
              inner
            >
              <RenameMajorEntity
                functionToExecute={handleRename}
                title={fetchedIndicatorParameters.current.title}
                description={fetchedIndicatorParameters.current.meaning}
                short_description={
                  fetchedIndicatorParameters.current.short_description
                }
              />
            </PopupModal>
          )}
          {modalIndicatorRange && (
            <PopupModal
              isOpen={modalIndicatorRange && !readOnly}
              onClose={() => setModalIndicatorRange(false)}
              title="Indicator Range"
              size="medium"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              customMessage="If you leave this page, the changes will not be saved."
              noChanges={!dateRangeSliderChanged}
            >
              <RangeSlider2
                dataArray={fullDates.current}
                initialDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                  fetchedIndicatorParameters.current.range_complete
                )}
                selectedDetails={selectedDataBoundaries}
                dateSliderMode={true}
                iconMode={'save'}
                customStyles={{
                  width: 400,
                  padding: '6px 20px 6px 5px'
                }}
                onApply={applyRangeChange}
                handleChange={() =>
                  !dateRangeSliderChanged &&
                  setDateRangeSliderChanged(!dateRangeSliderChanged)
                }
              />
            </PopupModal>
          )}
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default React.memo(IndicatorPage)
